export const flagdownMixin = {
    computed: {
        allowPriceEdit() {
            return (this.booking.FlagDown && this.driverState.CanDriverAmendPriceOnFlagDown) || this.isBluetoothMeterBooking;
        },
        priceUpdateRequired() {
            if (this.allowPriceEdit) {
                if (this.editedAmount) {
                    return true;
                }

                if (this.editedAmount === 0 && this.isBluetoothMeterBooking) {
                    return !['Card', 'OnAccount'].includes(this.booking.PaymentMethod);
                }
            }

            return false;
        },
        editedTotal() {
            const {Extras, Adjustments, WaitingTotal, WaitingTax, TaxAmount} = this.booking;

            const baseAmount = this.useLocalMeter ? this.localMeter.MeterCost : this.booking.ActualCost;
            let total = Number(this.editedAmount || baseAmount || 0);

            total += this.useLocalMeter ? this.localMeter.WaitingCost : WaitingTotal || 0;

            if (WaitingTax && WaitingTax > 0) {
                total += WaitingTax;
            }

            total += this.useLocalMeter ? this.localMeter.TaxAmount : TaxAmount || 0;

            if (Extras && Extras.length) {
                Extras.forEach(extra => {
                    total += extra.Amount + (extra.TaxAmount || 0);
                });
            }
            if (Adjustments && Adjustments.length) {
                Adjustments.forEach(adjustment => {
                    total += adjustment.Amount;
                    total += adjustment.TaxAmount;
                });
            }
            return total;
        },
    },
};
