var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full flex flex-col" },
    [
      _vm.isLoading ? _c("Loader") : _vm._e(),
      _c("Toast", {
        attrs: { message: _vm.toastMessage, type: _vm.toastType },
      }),
      _c(
        "Transition",
        { attrs: { name: _vm.animation, mode: "in-out" } },
        [
          _vm.page === "menu"
            ? _c("RideMenu", {
                attrs: { booking: _vm.booking },
                on: {
                  close: function ($event) {
                    _vm.page = "home"
                  },
                  open: _vm.handlePageOpen,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "Transition",
        { attrs: { name: "slide", mode: "out-in" } },
        [
          _vm.page === "ride-details"
            ? _c("RideDetails", {
                attrs: {
                  booking: _vm.booking,
                  "use-local-meter": _vm.useLocalMeter,
                  "local-meter": _vm.localMeter,
                },
                on: {
                  close: function ($event) {
                    _vm.page = "home"
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "Transition",
        { attrs: { name: "slide", mode: "out-in" } },
        [
          _vm.page === "add-drop"
            ? _c("DropOff", {
                attrs: { booking: _vm.booking, type: "add" },
                on: {
                  close: function ($event) {
                    _vm.page = "menu"
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "Transition",
        { attrs: { name: "slide", mode: "out-in" } },
        [
          _vm.page === "amend-drop"
            ? _c("DropOff", {
                attrs: { booking: _vm.booking, type: "amend" },
                on: {
                  close: function ($event) {
                    _vm.page = "menu"
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "Transition",
        { attrs: { name: "slide", mode: "out-in" } },
        [
          _vm.page === "add-via-stop"
            ? _c("AddViaStop", {
                attrs: { booking: _vm.booking },
                on: {
                  close: function ($event) {
                    _vm.page = "menu"
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "Transition",
        { attrs: { name: "slide", mode: "out-in" } },
        [
          _vm.page === "remove-via-stop"
            ? _c("RemoveViaStop", {
                attrs: { booking: _vm.booking },
                on: {
                  close: function ($event) {
                    _vm.page = "menu"
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "Transition",
        { attrs: { name: "slide", mode: "out-in" } },
        [
          _vm.page === "chat"
            ? _c("ChatScreen", {
                attrs: {
                  booking: _vm.booking,
                  conversation: _vm.conversation,
                  claims: _vm.claims,
                },
                on: {
                  close: function ($event) {
                    _vm.page = "home"
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("Transition", { attrs: { name: "slide-in", mode: "in-out" } }, [
        _vm.page === "home"
          ? _c(
              "div",
              { staticClass: "h-full flex flex-col overflow-hidden" },
              [
                _c("Header", {
                  attrs: {
                    "socket-status": _vm.socketStatus,
                    "button-type": _vm.buttonType,
                    status: _vm.booking.BookingStatus,
                    booking: _vm.booking,
                  },
                }),
                (_vm.booking.BookingStatus === "InProgress" &&
                  _vm.booking.CompletingDateTime) ||
                _vm.booking.BookingStatus === "Completed"
                  ? _c(
                      "div",
                      { staticClass: "journey flex-1 overflow-scroll" },
                      [
                        _c("CompletedBooking", {
                          attrs: {
                            booking: _vm.booking,
                            "app-data": _vm.appData,
                            "payment-link-sent": _vm.paymentLinkSent,
                            "use-local-meter": _vm.useLocalMeter,
                            "local-meter": _vm.localMeter,
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "journey flex-1 p-3 overflow-scroll" },
                      [
                        _vm.showJourneyTotal
                          ? _c("journey-total", {
                              attrs: {
                                booking: _vm.booking,
                                "pause-meter": _vm.confirmMeterPause,
                                "resume-meter": _vm.resumeMeter,
                                "use-local-meter": _vm.useLocalMeter,
                                "local-meter": _vm.localMeter,
                              },
                            })
                          : _vm._e(),
                        _vm.showJourneyBreakdown
                          ? _c("journey-breakdown", {
                              attrs: {
                                booking: _vm.booking,
                                "is-waiting": _vm.isManualTimerRunning,
                                "wait-point-id": _vm.waitPointId,
                                "use-local-meter": _vm.useLocalMeter,
                                "local-meter": _vm.localMeter,
                              },
                            })
                          : _vm._e(),
                        _c("journey-info", {
                          attrs: {
                            booking: _vm.booking,
                            "chat-unread-count":
                              _vm.conversation && _vm.conversation.UnreadCount,
                          },
                        }),
                      ],
                      1
                    ),
                _vm.booking.BookingStatus !== "Completed" || !_vm.disallowExit
                  ? _c("BookingStatusButton", {
                      attrs: {
                        booking: _vm.booking,
                        "is-waiting": _vm.isManualTimerRunning,
                        "is-loading": _vm.isLoading,
                        "wait-point-id": _vm.waitPointId,
                        "edited-amount": _vm.editedAmount,
                        "app-data": _vm.appData,
                        "booking-complete-confirmation":
                          _vm.bookingCompleteConfirmation,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.page !== "home"
        ? _c("BookingStatusButton", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false",
              },
            ],
            attrs: {
              booking: _vm.booking,
              "is-waiting": _vm.isManualTimerRunning,
              "is-loading": _vm.isLoading,
              "wait-point-id": _vm.waitPointId,
              "edited-amount": _vm.editedAmount,
              "app-data": _vm.appData,
              "booking-complete-confirmation": _vm.bookingCompleteConfirmation,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }