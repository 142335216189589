<template>
    <div id="app" class="h-full">
        <div class="w-full h-full flex justify-between flex-col chat-screen">
            <div class="text-center py-2 pl-2 pr-4 relative flex justify-between items-center">
                <div>
                    <ActionButton icon="arrow_back" color="transparent" :action="close" />
                </div>
                <h5 class="secondary mb-0">{{ booking.Passenger.Firstname }} {{ booking.Passenger.Surname }}</h5>
                <div>
                    <ActionButton icon="call" color="#36454F" :action="call" :small="true" icon-color="white" />
                </div>
            </div>
            <MessageList ref="messageList" id="message-list" :conversation="conversation" :claims="claims" :messages="messages" @setMessages="val => (messages = val)" />
            <div class="mt-auto message-edit relative">
                <MessageInput :send="send" />
            </div>
        </div>
    </div>
</template>

<script>
import {bus} from '@/main';
import {callPassenger} from '@/services/PostMessageService';
import {passengerConnect} from '@/services/PassengerConnectService';
import {scrollToLatest} from '@/utils/helper';
import {showErrorToast, showSuccessToast} from '../../services/ToastService';
import ActionButton from '@/components/ActionButton';
import MessageInput from './MessageInput.vue';
import MessageList from './MessageList';

export default {
    name: 'ChatScreen',
    props: {
        booking: {
            type: Object,
        },
        conversation: {
            type: Object,
        },
    },
    components: {
        MessageList,
        MessageInput,
        ActionButton,
    },
    data() {
        return {
            messages: [],
            timers: [],
        };
    },
    methods: {
        async connectPassenger() {
            try {
                this.connectingPassenger = true;
                await passengerConnect(this.booking.Id);
                this.connectingPassenger = false;
                this.$emit('close');
                showSuccessToast('The passenger connect call has been initiated.');
            } catch (exc) {
                showErrorToast(exc, 'Something went wrong while connecting your call.');
                this.connectingPassenger = false;
            }
        },
        callPassenger() {
            callPassenger(this.passengerMobile);
        },
        async call() {
            if (this.driverState.DriverConnect) {
                await this.connectPassenger();
            } else {
                this.callPassenger();
            }
        },
        processNewChatMessage({message, conversation}) {
            if (conversation._id !== (this.conversation && this.conversation._id)) {
                return;
            }

            if (message.SenderId === this.claims.DriverId) {
                const foundMessageRefIndex = this.messages.findIndex(msg => msg.RefId === message.RefId);
                if (foundMessageRefIndex > -1) {
                    this.messages.splice(foundMessageRefIndex, 1, message);
                    clearTimeout(this.timers[message.RefId]);
                }
            }

            const existingMessage = this.messages.find(item => item._id === message._id);
            if (!existingMessage) {
                this.messages.push(message);
                scrollToLatest();
            }
        },
        close() {
            this.$emit('close');
        },
        send(content) {
            try {
                if (!content) {
                    return;
                }
                const sender = this.claims.DriverId;
                const message = {
                    ConversationId: this.conversation._id,
                    Body: content,
                    SenderId: sender,
                    TenantId: this.claims.TenantId,
                    RefId: sender + '|' + Date.now(),
                    DateTime: new Date(),
                    Read: false,
                    Attachments: [],
                };
                this.socket.emit('SEND_MESSAGE', message);
                this.messages.push(message);
                scrollToLatest();
                this.runFailedMessageTimer(message.RefId);
            } catch (exc) {
                console.log(exc);
            }
        },
        runFailedMessageTimer(refId) {
            this.timers[refId] = setTimeout(() => {
                const index = this.messages.findIndex(msg => msg.RefId === refId);
                if (index > -1) {
                    this.messages.splice(index, 1, {
                        ...this.messages[index],
                        IsFailed: true,
                    });
                    scrollToLatest();
                }
            }, 5000);
        },
        reloadPage() {
            window.location.reload();
        },
        setClaims() {
            let user = localStorage.getItem('user');
            if (user) {
                user = JSON.parse(user);
                let claims = user.Claims.split('|');
                let claimsObj = {};
                claims.forEach(claim => {
                    let keyValues = claim.split(':');
                    claimsObj[keyValues[0]] = keyValues[1];
                });
                this.claims = claimsObj;
            } else {
                this.claims = {};
            }
        },
    },
    created() {
        this.setClaims();

        bus.$on('newChatMessage', this.processNewChatMessage);
    },
    destroyed() {
        bus.$off('newChatMessage', this.processNewChatMessage);
    },
};
</script>

<style lang="scss">
.ProseMirror {
    font-size: 1.12rem;
    padding-right: 1.75rem;
}
.no-select {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.no-select:focus {
    outline: none !important;
}

.chat-screen {
    background-color: #f4f5f0;
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media (prefers-color-scheme: dark) {
    .chat-screen {
        background-color: #282828;
    }
}
</style>
