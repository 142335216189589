var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-full", attrs: { id: "app" } }, [
    _c(
      "div",
      {
        staticClass: "w-full h-full flex justify-between flex-col chat-screen",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "text-center py-2 pl-2 pr-4 relative flex justify-between items-center",
          },
          [
            _c(
              "div",
              [
                _c("ActionButton", {
                  attrs: {
                    icon: "arrow_back",
                    color: "transparent",
                    action: _vm.close,
                  },
                }),
              ],
              1
            ),
            _c("h5", { staticClass: "secondary mb-0" }, [
              _vm._v(
                _vm._s(_vm.booking.Passenger.Firstname) +
                  " " +
                  _vm._s(_vm.booking.Passenger.Surname)
              ),
            ]),
            _c(
              "div",
              [
                _c("ActionButton", {
                  attrs: {
                    icon: "call",
                    color: "#36454F",
                    action: _vm.call,
                    small: true,
                    "icon-color": "white",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _c("MessageList", {
          ref: "messageList",
          attrs: {
            id: "message-list",
            conversation: _vm.conversation,
            claims: _vm.claims,
            messages: _vm.messages,
          },
          on: {
            setMessages: function (val) {
              return (_vm.messages = val)
            },
          },
        }),
        _c(
          "div",
          { staticClass: "mt-auto message-edit relative" },
          [_c("MessageInput", { attrs: { send: _vm.send } })],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }