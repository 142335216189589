import Swal from 'sweetalert2';
export const confirmAndRunAction = (confirmTitle, confirmSubTitle, successTitle, successSubTitle, failureTitle, failureSubTitle, action, successAction) => {
    Swal.fire({
        title: confirmTitle,
        text: confirmSubTitle,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        position: 'bottom',
        showCancelButton: true,
        confirmButtonColor: '#e90000',
        preConfirm: result => {
            return new Promise(async resolve => {
                if (result) {
                    try {
                        await action();
                        if (successTitle && successSubTitle) {
                            await Swal.fire({
                                title: successTitle,
                                text: successSubTitle,
                                confirmButtonText: 'Confirm',
                                position: 'bottom',
                                confirmButtonColor: '#9e9e9e',
                                showClass: {
                                    popup: 'animated slideInUp',
                                },
                                hideClass: {
                                    popup: 'animated slideOutDown',
                                },
                                heightAuto: false,
                            }).then(async () => {
                                await successAction();
                            });
                        } else {
                            await successAction();
                        }
                        resolve();
                    } catch (err) {
                        if (failureTitle) {
                            await Swal.fire({
                                title: failureTitle,
                                text: err?.data?.message || failureSubTitle,
                                confirmButtonText: 'Try Again',
                                position: 'bottom',
                                confirmButtonColor: '#e90000',
                                showClass: {
                                    popup: 'animated slideInUp',
                                },
                                hideClass: {
                                    popup: 'animated slideOutDown',
                                },
                                heightAuto: false,
                            });
                        }
                        resolve();
                    }
                } else {
                    resolve();
                }
            });
        },
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        showClass: {
            popup: 'animated slideInUp',
        },
        hideClass: {
            popup: 'animated slideOutDown',
        },
        heightAuto: false,
    });
};

export const showErrorDialog = (err, failureSubTitle, confirmTitle, dialogTitle, customClass) => {
    Swal.fire({
        title: dialogTitle || 'Oops',
        text: err?.data?.message || failureSubTitle,
        confirmButtonText: confirmTitle,
        position: 'bottom',
        confirmButtonColor: '#e90000',
        showClass: {
            popup: 'animated slideInUp',
        },
        hideClass: {
            popup: 'animated slideOutDown',
        },
        heightAuto: false,
        ...(customClass ? {customClass} : {}),
    });
};

export const showErrorActionDialog = (err, failureSubTitle, confirmTitle, action) => {
    Swal.fire({
        title: 'Oops',
        text: err?.data?.message || failureSubTitle,
        confirmButtonText: confirmTitle,
        position: 'bottom',
        confirmButtonColor: '#e90000',
        showClass: {
            popup: 'animated slideInUp',
        },
        hideClass: {
            popup: 'animated slideOutDown',
        },
        heightAuto: false,
    }).then(result => {
        if (result.isConfirmed) {
            action();
        }
    });
};

export const showWarningDialog = (warningTitle, warningSubTitle, confirmTitle) => {
    Swal.fire({
        title: warningTitle,
        text: warningSubTitle,
        confirmButtonText: confirmTitle,
        position: 'bottom',
        confirmButtonColor: '#FBBF24',
        showClass: {
            popup: 'animated slideInUp',
        },
        hideClass: {
            popup: 'animated slideOutDown',
        },
        heightAuto: false,
    });
};

export const showSuccessDialog = async (successSubTitle, confirmTitle) => {
    return await Swal.fire({
        title: 'Success',
        text: successSubTitle,
        confirmButtonText: confirmTitle,
        position: 'bottom',
        confirmButtonColor: '#10B981',
        showClass: {
            popup: 'animated slideInUp',
        },
        hideClass: {
            popup: 'animated slideOutDown',
        },
        heightAuto: false,
    });
};

export const showActionsDialog = (title, subTitle, nextAction, cancelButtonText, htmlFormat) => {
    Swal.fire({
        title: title,
        ...(htmlFormat ? {html: subTitle} : {text: subTitle}),
        confirmButtonText: nextAction.buttonText,
        cancelButtonText: cancelButtonText || 'Cancel',
        position: 'bottom',
        showCancelButton: true,
        confirmButtonColor: '#3063E9',
        preConfirm: result => {
            return new Promise(async resolve => {
                if (result) {
                    try {
                        await nextAction.exec();
                        if (nextAction.showSuccessDialog) {
                            await Swal.fire({
                                title: nextAction.successTitle,
                                text: nextAction.successSubTitle,
                                confirmButtonText: 'Confirm',
                                position: 'bottom',
                                confirmButtonColor: '#9e9e9e',
                                showClass: {
                                    popup: 'animated slideInUp',
                                },
                                hideClass: {
                                    popup: 'animated slideOutDown',
                                },
                                heightAuto: false,
                            }).then(async () => {
                                if (nextAction.successAction) {
                                    await nextAction.successAction();
                                }
                            });
                        }
                        resolve();
                    } catch (err) {
                        if (nextAction.showErrorDialog) {
                            await Swal.fire({
                                title: nextAction.failureTitle,
                                text: err?.data?.message || nextAction.failureSubTitle,
                                confirmButtonText: 'Try Again',
                                position: 'bottom',
                                confirmButtonColor: '#e90000',
                                showClass: {
                                    popup: 'animated slideInUp',
                                },
                                hideClass: {
                                    popup: 'animated slideOutDown',
                                },
                                heightAuto: false,
                            });
                        }
                        resolve();
                    }
                } else {
                    resolve();
                }
            });
        },
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        showClass: {
            popup: 'animated slideInUp',
        },
        hideClass: {
            popup: 'animated slideOutDown',
        },
        heightAuto: false,
    });
};
