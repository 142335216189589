<template>
    <div class="h-full flex flex-col">
        <div class="flex items-center px-2">
            <action-button icon="west" color="transparent" :action="closeMenu" />
        </div>
        <div class="flex-1 flex flex-col gap-0 p-4">
            <h2>Ride Menu</h2>
            <MenuItem @click.native="() => open('ride-details')" icon="description" text="Ride Details" />
            <MenuItem v-if="booking.BookingSource === 'APP' && driverState.ShowPassengerChat" @click.native="() => open('chat')" icon="chat_bubble_outline" text="Chat" />
            <MenuItem
                v-if="driverState.DriverConnect"
                :success="connectingPassenger"
                @click.native="connectPassenger"
                icon="call"
                :text="connectingPassenger ? 'Calling Passenger' : 'Passenger Connect'" />
            <template v-else>
                <MenuItem @click.native="callPassenger" icon="call" text="Call Passenger" />
                <MenuItem @click.native="textPassenger" icon="textsms" text="Text Passenger" />
            </template>
            <MenuItem @click.native="requestCall" icon="call" :text="booking._callRequested ? 'Call From Office Requested' : 'Request Call From Office'" :success="booking._callRequested" />
            <MenuItem
                v-if="driverState.CanDriverSendPaymentLink && booking.BookingStatus === 'InProgress' && !paymentCompleted && booking.PaymentMethod !== 'OnAccount' && booking.PaymentMethod !== 'Card'"
                @click.native="showPaymentLinkScreen = true"
                icon="textsms"
                text="Send Payment Link" />
            <div class="" v-if="driverState.CanDriverAmendStops && (booking.BookingStatus === 'Arrived' || booking.BookingStatus === 'InProgress')">
                <MenuItem v-if="!booking.AsDirected" @click.native="() => open('add-via-stop')" icon="add_location_alt" text="Add Via Stop" />
                <MenuItem v-if="!booking.AsDirected && booking.BookingStops.length > 2" @click.native="() => open('remove-via-stop')" icon="wrong_location" text="Remove Via Stop" />
                <MenuItem v-if="booking.AsDirected" @click.native="() => open('add-drop')" icon="add_location" text="Add Dropoff" />
                <MenuItem v-if="!booking.AsDirected" @click.native="() => open('amend-drop')" icon="edit" text="Amend Dropoff" />
            </div>
            <MenuItem @click.native="resumeFollowOns" icon="play_circle" text="Resume Follow-on Jobs" :success="true" :disabled="loadingFollowOnState" v-if="followOnPaused" />
            <MenuItem @click.native="pauseFollowOns" icon="pause_circle" text="Pause Follow-on Jobs" :danger="true" :disabled="loadingFollowOnState" v-else />
            <MenuItem
                v-if="driverState.CanDriverHandback && (booking.BookingStatus === 'Allocated' || booking.BookingStatus === 'OnRoute')"
                @click.native="confirmHandBack"
                icon="block"
                text="Handback Booking"
                :danger="true" />
            <MenuItem v-if="driverState.CanDriverCOA && booking.BookingStatus === 'Arrived'" @click.native="confirmNoShow" icon="person_off" text="Passenger No Show" :danger="true" />
        </div>
        <SendPaymentLink v-if="showPaymentLinkScreen" :booking="booking" @cancel="showPaymentLinkScreen = false" />
    </div>
</template>

<script>
import {bus} from '@/main';
import {callPassenger, textPassenger} from '@/services/PostMessageService';
import {clearAllWaiting} from '@/utils/helper';
import {coaBooking, handbackBooking} from '@/services/StatusService.js';
import {confirmAndRunAction, showErrorDialog, showSuccessDialog, showWarningDialog} from '@/services/DialogService';
import {getDriverState} from '@/services/AuthService';
import {getQueuePosition, requestCallFromOffice} from '@/services/CallRequestService';
import {passengerConnect} from '@/services/PassengerConnectService';
import {pauseResumeFollowOn} from '@/services/FollowOnService';
import {showErrorToast, showSuccessToast} from '../../services/ToastService';
import ActionButton from '@/components/ActionButton';
import MenuItem from './MenuItem';
import SendPaymentLink from '@/components/SendPaymentLink';

export default {
    name: 'RideMenu',
    props: {
        booking: {
            type: Object,
        },
    },
    components: {
        ActionButton,
        MenuItem,
        SendPaymentLink,
    },
    data() {
        return {
            showDialog: false,
            dialog: null,
            followOnPaused: null,
            loadingFollowOnState: true,
            showPaymentLinkScreen: false,
            connectingPassenger: false,
        };
    },
    methods: {
        confirmNoShow() {
            if (this.booking.PaymentMethod === 'OnAccount') {
                showErrorDialog(null, 'Sorry you can not COA (No Show) an account booking. Please contact an operator.', 'Continue');
                return;
            }
            confirmAndRunAction(
                'Confirm No Show',
                'Are you sure your passenger has not/will not turn up for this booking?',
                'No Show Successful.',
                'Please tap on confirm to continue',
                'Oops!',
                'Some Error Occurred whilst setting the booking as a no show.',
                async () => {
                    await coaBooking(this.booking.Id);
                },
                () => {
                    clearAllWaiting(this.booking);
                    bus.$emit('closeWindow');
                }
            );
        },
        confirmHandBack() {
            confirmAndRunAction(
                'Confirm Handback',
                'Are you sure you want to handback this booking?',
                'Handback Successful.',
                'Please tap on confirm to continue',
                'Oops!',
                'Some Error Occurred in handing back the booking.',
                async () => {
                    await handbackBooking(this.booking.Id);
                },
                () => {
                    clearAllWaiting(this.booking);
                    bus.$emit('closeWindow');
                }
            );
        },
        pauseFollowOns() {
            if (this.loadingFollowOnState) return;
            confirmAndRunAction(
                'Pause Follow-on Jobs',
                'Are you sure you want to stop getting follow on jobs?',
                null,
                null,
                null,
                null,
                async () => {
                    try {
                        bus.$emit('loading', true);
                        await pauseResumeFollowOn({
                            pause: true,
                        });
                        bus.$emit('loading', false);
                        this.followOnPaused = true;
                        showSuccessToast('Follow-on jobs paused successfully');
                    } catch (err) {
                        bus.$emit('loading', false);
                        showErrorToast(err, 'Some error occurred whilst trying to pause follow-on jobs.');
                    }
                },
                () => {}
            );
        },
        resumeFollowOns() {
            if (this.loadingFollowOnState) return;
            confirmAndRunAction(
                'Resume Follow-on Jobs',
                'Are you sure you want to start getting follow on jobs?',
                null,
                null,
                null,
                null,
                async () => {
                    try {
                        bus.$emit('loading', true);
                        await pauseResumeFollowOn({
                            pause: false,
                        });
                        bus.$emit('loading', false);
                        this.followOnPaused = false;
                        showSuccessToast('Follow-on jobs resumed successfully');
                    } catch (err) {
                        bus.$emit('loading', false);
                        showErrorToast(err, 'Some error occurred whilst trying to resume follow-on jobs.');
                    }
                },
                () => {}
            );
        },
        closeMenu() {
            this.$emit('close');
        },
        reload() {
            window.location.reload();
        },
        open(page) {
            this.$emit('open', page);
        },
        handleClose() {
            this.showDialog = false;
            this.dialog = null;
        },
        async connectPassenger() {
            try {
                this.connectingPassenger = true;
                await passengerConnect(this.booking.Id);
                this.connectingPassenger = false;
                this.$emit('close');
                showSuccessToast('The passenger connect call has been initiated.');
            } catch (exc) {
                showErrorToast(exc, 'Something went wrong while connecting your call.');
                this.connectingPassenger = false;
            }
        },
        callPassenger() {
            callPassenger(this.passengerMobile);
        },
        textPassenger() {
            textPassenger(this.passengerMobile);
        },
        async requestCall() {
            try {
                bus.$emit('loading', true);
                const res = await requestCallFromOffice(this.booking.Id);

                let position;
                if (res.position === 1) position = '1st';
                else if (res.position === 2) position = '2nd';
                else if (res.position === 3) position = '3rd';
                else position = `${res.position}th`;

                if (res.existingRequest) {
                    showWarningDialog('Pending', `Call already requested! You are ${position} in the queue`, 'OK');
                } else {
                    showSuccessDialog(`Call requested successfully. You are ${position} in the queue`, 'Confirm');
                }
                bus.$emit('loading', false);
                bus.$emit('bookingUpdate', {...this.booking, _callRequested: true});
            } catch (err) {
                bus.$emit('loading', false);
                showErrorDialog(err, 'Something went wrong while requesting your call.', 'Try Again');
            }
        },
        async getQueue() {
            try {
                const queue = await getQueuePosition();
                if (queue.position) {
                    this.booking._callRequested = true;
                } else {
                    this.booking._callRequested = false;
                }
            } catch (err) {
                showErrorToast(err, 'Could not fetch office call request info');
            }
        },
        async fetchDriverState() {
            try {
                this.loadingFollowOnState = true;
                const state = await getDriverState();
                this.followOnPaused = state.PauseFollowOn;
                this.loadingFollowOnState = false;
            } catch (err) {
                this.loadingFollowOnState = false;
                showErrorToast(err, 'Could not fetch follow on paused state info');
            }
        },
    },
    computed: {
        paymentCompleted() {
            return this.booking?.CardPaymentStatus && this.booking?.CardPaymentStatus.toLowerCase() === 'success';
        },
        passengerMobile() {
            let nextStop;
            if (this.booking.NextStop && this.booking.BookingStatus === 'InProgress') {
                nextStop = this.booking.BookingStops[this.booking.NextStop - 1];
            } else {
                nextStop = this.booking.BookingStops[0];
            }

            if (nextStop && nextStop.Passenger && nextStop.Passenger.Mobile) {
                return nextStop.Passenger.Mobile;
            }
            return this.booking.PassengerNotificationPhone;
        },
    },
    async mounted() {
        this.getQueue();
        this.fetchDriverState();
    },
};
</script>

<style lang="scss">
.swal2-container {
    padding: 0 !important;
    .swal2-popup {
        border-radius: 16px 16px 0 0;
    }
    .swal2-title {
        @apply text-left px-3 py-3 pb-1 text-2xl;
    }
    .swal2-html-container {
        @apply text-left px-3 py-0 m-0;
    }
    .swal2-loader {
        @apply my-4;
        border-color: #e90000 transparent #e90000 transparent;
    }
    .swal2-actions {
        @apply w-full px-2;
        background-image: none !important;
        button {
            @apply w-full border-0 text-lg font-semibold;
            background-image: none !important;
            box-shadow: none !important;
            color: white !important;
            outline: 0;
        }
    }
}
</style>
