import {bus} from '@/main';
import {getLocationsFromServer, saveLocationsDebugInfo} from '../services/LocationsService';
import {handleLocationsStoringError} from '../utils/sentryErrors';
import {handlePriceUpdate} from '../services/PostMessageService';
import {haversineDistance} from '../utils/helper';
import dayjs from 'dayjs';

const BOOKING_STATUSES = {
    Rejected: -20,
    Unconfirmed: -10,
    Cancelled: -1,
    COA: 0,
    Incoming: 1,
    PreAllocated: 5,
    Allocated: 10,
    OnRoute: 20,
    Arrived: 30,
    InProgress: 40,
    Clearing: 80,
    Completed: 100,
    OpenToBid: 120,
};

const MPH_TO_MPS = 0.44704; // Conversion factor from miles per hour to meters per second
const EARTH_RADIUS = 6371000; // Earth radius in meters

export const locationsMixin = {
    data() {
        return {
            bookingLocations: [],
            priceUpdates: [],
            fetchingLocations: false,
        };
    },
    methods: {
        setLocations(locations) {
            this.bookingLocations = locations;
            if (typeof sessionStorage !== 'undefined') {
                try {
                    sessionStorage.setItem(`locations:${this.booking.Id.toLowerCase()}`, JSON.stringify(locations));
                } catch (e) {
                    if (e.message) {
                        const err = {
                            message: e.message + ` (Locations count: ${locations ? locations.length : 0}`,
                            stack: e.stack,
                        };
                        handleLocationsStoringError(err, 'Error saving locations to sessionStorage');
                    } else {
                        handleLocationsStoringError(e, 'Error saving locations to sessionStorage');
                    }
                }
            } else {
                handleLocationsStoringError(null, 'SessionStorage is not available');
            }
        },
        getLocationsFromSessionStorage() {
            let storedLocations = [];

            if (typeof sessionStorage !== 'undefined') {
                try {
                    const locations = sessionStorage.getItem(`locations:${this.booking.Id.toLowerCase()}`);
                    if (locations) {
                        storedLocations = JSON.parse(locations);
                    }
                } catch (e) {
                    handleLocationsStoringError(e, 'Error retrieving locations from sessionStorage');
                    return this.bookingLocations;
                }
            } else {
                handleLocationsStoringError(null, 'SessionStorage is not available');
                return this.bookingLocations;
            }

            return storedLocations;
        },
        saveLocationToSessionStorage(locationData) {
            try {
                const storedLocations = this.getLocationsFromSessionStorage();

                // Add the new location data
                storedLocations.push({
                    TimeStamp: new Date().toISOString(),
                    Latitude: locationData.lat || locationData.latitude,
                    Longitude: locationData.lon || locationData.longitude,
                    Speed: locationData.speed,
                    Bearing: locationData.bearing,
                    Accuracy: locationData.accuracy,
                    Provider: locationData.provider,
                    MeterPaused: !!this.booking.MeterPaused,
                    IsCompleting: !!this.booking.CompletingDateTime,
                    BookingStatus: BOOKING_STATUSES[this.booking.BookingStatus],
                });

                // Save the updated locations array back to sessionStorage
                this.setLocations(storedLocations);
            } catch (err) {
                handleLocationsStoringError(err, 'Error adding location');
            }
        },
        async resyncLocations() {
            try {
                bus.$emit('setPriceLoader', true);

                const serverLocations = await this.fetchLocationsFromServer();
                const finalLocations = this.mergeLocationData(serverLocations);
                this.setLocations(finalLocations);

                this.addPriceUpdate();

                bus.$emit('setPriceLoader', false);

                if (this.useLocalMeter && this.booking.BookingStatus === 'InProgress' && this.booking.AutomaticStationaryTime) {
                    // reset local waiting to use newly calculated value based on new locations, only for stationary waiting
                    bus.$emit('clearLocalWaiting');
                }
            } catch (err) {
                handleLocationsStoringError(err, 'Error re-syncing locations');
                bus.$emit('setPriceLoader', false);
            }
        },
        async fetchLocationsFromServer() {
            try {
                this.fetchingLocations = true;
                const result = await getLocationsFromServer(this.booking.Id);
                this.fetchingLocations = false;
                return result;
            } catch (e) {
                if (e && e.isNetworkError) {
                    console.error(e);
                } else {
                    handleLocationsStoringError(e, 'Error fetching locations from server');
                }
                this.fetchingLocations = false;
                return [];
            }
        },
        mergeLocationData(serverLocations) {
            const storedLocations = this.getLocationsFromSessionStorage();
            if (serverLocations && serverLocations.length) {
                const latestServerLocation = serverLocations[0];
                const time = dayjs(latestServerLocation.TimeStamp);

                const filteredLocations = storedLocations.filter(loc => dayjs(loc.TimeStamp).isAfter(time));

                return [...serverLocations.reverse(), ...filteredLocations];
            }
            return storedLocations;
        },
        isPriceLower(oldPrices, newPrices) {
            if (oldPrices && newPrices) {
                return (newPrices.JourneyCost || 0) + (newPrices.StationaryCost || 0) < (oldPrices.JourneyCost || 0) + (oldPrices.StationaryCost || 0);
            }
            if (newPrices && this.useLocalMeter) {
                return (
                    (newPrices.JourneyCost || 0) + this.calculateStationaryCost(newPrices.StationaryTime || 0) <
                    (this.booking.MeterCost || 0) + this.calculateStationaryCost(this.booking.StationaryTime || 0)
                );
            }
            return false;
        },
        isPriceDifferent(oldPrices, newPrices) {
            if (oldPrices && newPrices) {
                return (
                    Math.abs(Number((newPrices.JourneyCost - oldPrices.JourneyCost).toFixed(2))) >= this.booking.RoundTo ||
                    Math.abs(Number((newPrices.StationaryCost - oldPrices.StationaryCost).toFixed(2))) >= this.booking.RoundTo
                );
            }
            return true;
        },
        addPriceUpdate(updatedPrices, forceUpdatePrices) {
            if (this.fetchingLocations) {
                return;
            }

            if (!updatedPrices) {
                updatedPrices = this.calculatePrices();
            }

            if (!updatedPrices) {
                return;
            }

            let newUpdateReceived = false;
            if (this.priceUpdates.length) {
                if (this.isPriceLower(this.priceUpdates[this.priceUpdates.length - 1], updatedPrices)) {
                    return;
                }

                if (this.isPriceDifferent(this.priceUpdates[this.priceUpdates.length - 1], updatedPrices)) {
                    this.priceUpdates.push(updatedPrices);
                    newUpdateReceived = true;
                }
            } else {
                if (this.isPriceLower(null, updatedPrices)) {
                    updatedPrices = {
                        TimeStamp: new Date().toISOString(),
                        Distance: this.booking.ActualDistance,
                        StationaryTime: this.booking.StationaryTime,
                        JourneyCost: this.booking.MeterCost,
                        StationaryCost: this.calculateStationaryCost(this.booking.StationaryTime),
                        WaitingTime: this.calculateTotalWaitingTime(this.booking.StationaryTime),
                        WaitingCost: this.calculateWaitingCost(this.booking.StationaryTime),
                    };
                }
                this.priceUpdates.push(updatedPrices);
                newUpdateReceived = true;
            }

            if (this.useLocalMeter && (newUpdateReceived || forceUpdatePrices)) {
                this.socket &&
                    this.socket.emit('CAB9GO_PRICE_UPDATE', {
                        // local prices
                        JourneyCost: updatedPrices.JourneyCost,
                        ActualDistance: updatedPrices.Distance,
                        WaitingTime: Math.round(updatedPrices.WaitingTime || 0),
                        WaitingCost: updatedPrices.WaitingCost,
                        StationaryTime: updatedPrices.StationaryTime,
                        StationaryCost: updatedPrices.StationaryCost,
                        // other details
                        BookingId: this.booking.Id.toLowerCase(),
                        TenantId: this.booking.TenantId.toLowerCase(),
                        DriverId: this.booking.DriverId && this.booking.DriverId.toLowerCase(),
                        ExtrasCost: this.booking._extrasTotal || 0,
                        CompletingDateTime: this.booking.CompletingDateTime,
                        MeterPaused: this.booking.MeterPaused,
                    });
            }

            bus.$emit('updateLocalMeter', {
                MeterCost: updatedPrices.JourneyCost,
                TaxAmount: Number((updatedPrices.JourneyCost * this.booking.TaxRatio).toFixed(2)),
                ActualDistance: updatedPrices.Distance,
                WaitingTime: updatedPrices.WaitingTime,
                WaitingCost: updatedPrices.WaitingCost,
                StationaryTime: updatedPrices.StationaryTime,
                StationaryCost: updatedPrices.StationaryCost,
            });

            handlePriceUpdate({
                MeterCost: updatedPrices.JourneyCost,
                ActualCost: updatedPrices.JourneyCost,
                TaxAmount: Number((updatedPrices.JourneyCost * this.booking.TaxRatio).toFixed(2)),
                ActualDistance: updatedPrices.Distance,
                WaitingTime: Math.round(updatedPrices.WaitingTime || 0),
                WaitingCost: updatedPrices.WaitingCost,
                ExtrasCost: this.booking._extrasTotal || 0,
                StationaryTime: updatedPrices.StationaryTime,
                BookingId: this.booking.Id.toLowerCase(),
                TenantId: this.booking.TenantId.toLowerCase(),
                DriverId: this.booking.DriverId && this.booking.DriverId.toLowerCase(),
            });
        },
        getFilteredLocations() {
            return (this.bookingLocations || []).filter(location => {
                if (location.Accuracy && Number(location.Accuracy) > 20) {
                    return false;
                }
                return location.BookingStatus === 40 && Number(location.Speed) >= 0;
            });
        },
        getSegments(locations) {
            let currentSegment = [];
            const segments = [currentSegment];

            let prevMeterPaused = false;

            for (let ind = 0; ind < locations.length; ind++) {
                const location = locations[ind];
                if (location.MeterPaused || location.IsCompleting) {
                    if (!prevMeterPaused) {
                        currentSegment = [];
                        segments.push(currentSegment);
                    }
                    prevMeterPaused = true;
                } else {
                    currentSegment.push(location);
                    prevMeterPaused = false;
                }
            }

            return segments;
        },
        calculateSegmentMetrics(segment) {
            if (segment.length < 2) {
                // todo: calculate using next location?
                return {
                    Distance: 0,
                    StationaryTime: 0,
                };
            }

            const result = this.calculateDistanceAndStationaryTime(segment);
            const totalDistanceInMiles = result.totalDistance * 0.000621371;

            return {
                Distance: totalDistanceInMiles,
                StationaryTime: result.totalStationaryTime,
            };
        },
        getSegmentsTotals(segmentsPrices) {
            let distance = 0;
            let stationaryTime = 0;

            segmentsPrices.forEach(price => {
                distance += price.Distance;
                stationaryTime += price.StationaryTime;
            });

            return {
                Distance: distance,
                StationaryTime: stationaryTime,
            };
        },
        calculatePrices() {
            try {
                const locations = this.getFilteredLocations();

                if (locations.length < 2) {
                    return {
                        TimeStamp: new Date().toISOString(),
                        Distance: 0,
                        StationaryTime: 0,
                        JourneyCost: this.calculateJourneyPrice(0),
                        StationaryCost: 0,
                        WaitingTime: this.calculateTotalWaitingTime(0),
                        WaitingCost: this.calculateWaitingCost(0),
                    };
                }

                const segments = this.getSegments(locations);

                const segmentsMetrics = segments.map(segment => this.calculateSegmentMetrics(segment));

                const segmentsTotals = this.getSegmentsTotals(segmentsMetrics);

                segmentsTotals.Distance = Number(segmentsTotals.Distance.toFixed(2));

                const journeyCost = this.calculateJourneyPrice(segmentsTotals.Distance);

                const stationaryCost = this.calculateStationaryCost(segmentsTotals.StationaryTime);
                const waitingTime = this.calculateTotalWaitingTime(segmentsTotals.StationaryTime);
                const waitingCost = this.calculateWaitingCost(segmentsTotals.StationaryTime);

                return {
                    TimeStamp: new Date().toISOString(),
                    ...segmentsTotals,
                    JourneyCost: journeyCost,
                    StationaryCost: stationaryCost,
                    WaitingTime: waitingTime,
                    WaitingCost: waitingCost,
                };
            } catch (err) {
                handleLocationsStoringError(err, 'Error calculating prices');
            }
        },
        async uploadLocations() {
            try {
                const locations = this.getFilteredLocations();
                if (locations.length < 2) {
                    handleLocationsStoringError(null, `Not enough locations to calculate distance and time. #${this.booking.LocalId}`);
                    return;
                }

                const prices = this.calculatePrices();
                this.addPriceUpdate(prices);

                await saveLocationsDebugInfo(this.booking.Id, {
                    ...prices,
                    StoredLocations: this.bookingLocations,
                    PriceUpdates: this.priceUpdates,
                });
            } catch (e) {
                handleLocationsStoringError(e, 'Error saving locations on server');
            }
        },
        calculateDistanceAndStationaryTime(locations) {
            let totalDistance = 0;
            let totalStationaryTime = 0;

            for (let i = 1; i < locations.length; i++) {
                const prev = locations[i - 1];
                const current = locations[i];

                // Calculate distance between two points using Haversine formula
                const dLat = ((current.Latitude - prev.Latitude) * Math.PI) / 180;
                const dLon = ((current.Longitude - prev.Longitude) * Math.PI) / 180;
                const lat1 = (prev.Latitude * Math.PI) / 180;
                const lat2 = (current.Latitude * Math.PI) / 180;

                const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

                const distance = EARTH_RADIUS * c;
                totalDistance += distance;

                // Calculate time difference between two points
                const timeDifference = (new Date(current.TimeStamp) - new Date(prev.TimeStamp)) / 1000; // in seconds

                // If speed is below the stationary threshold, add to total stationary time
                if (this.booking.AutomaticStationaryTime && prev.Speed <= this.stationaryThreshold) {
                    totalStationaryTime += timeDifference;
                }
            }

            return {
                totalDistance: totalDistance, // in meters
                totalStationaryTime: totalStationaryTime, // in seconds
            };
        },
        calculateTotalWaitingTime(stationaryTime) {
            const totalWaitingTime = (this.booking.StopsWaitingTime || 0) + (stationaryTime || 0);
            return totalWaitingTime;
        },
        calculateWaitingCost(stationaryTime) {
            const stationaryTimeUnits = Math.floor(stationaryTime / (this.booking.WaitTimeUnit || 60));
            const stopsWaitingTimeUnits = Math.ceil((this.booking.StopsWaitingTime || 0) / (this.booking.WaitTimeUnit || 60));
            let calculatedPrice = this.booking.WaitTimeCharge * (stationaryTimeUnits + stopsWaitingTimeUnits);
            if (this.booking.FareMultiplier) {
                calculatedPrice = calculatedPrice * this.booking.FareMultiplier;
            }
            return this.roundPrice(calculatedPrice);
        },
        calculateStationaryCost(stationaryTime) {
            let calculatedPrice = this.booking.WaitTimeCharge * Math.floor(stationaryTime / (this.booking.WaitTimeUnit || 60));
            if (this.booking.FareMultiplier) {
                calculatedPrice = calculatedPrice * this.booking.FareMultiplier;
            }
            return this.roundPrice(calculatedPrice);
        },
        roundPrice(price) {
            price = Number(price.toFixed(4));

            let roundedVal;
            const roundMode = this.booking.RoundMode;
            const roundTo = this.booking.RoundTo;

            switch (roundMode) {
                case 0: // nearest
                    roundedVal = Math.round(price / roundTo) * roundTo;
                    break;
                case 1: // up
                    roundedVal = Math.ceil(price / roundTo) * roundTo;
                    break;
                case 2: // down
                    roundedVal = Math.floor(price / roundTo) * roundTo;
                    break;
                default:
                    roundedVal = price;
                    break;
            }

            return Number(roundedVal.toFixed(2));
        },
        calculateJourneyPrice(totalDistance) {
            const baseCharge = this.booking.StandingCharge || 0;
            let total = baseCharge;

            // charge based on mileage
            if (this.booking.FarePerMileSteps) {
                total += this.calculatePriceUsingSteps(totalDistance);
            } else {
                total += totalDistance * this.booking.FarePerMile * (1 / (this.booking.FareUnit || 1));
            }

            // journey minimum
            const isJourneyEnd = this.booking.BookingStatus === 'Completed' || (this.booking.BookingStatus === 'InProgress' && this.booking.CompletingDateTime);
            let calculatedPrice = this.minimumCharge && isJourneyEnd ? Math.max(total, this.minimumCharge) : total;

            // other journey charges
            calculatedPrice = calculatedPrice + this.extraCharges;

            // peak multiplier
            if (this.booking.FareMultiplier) {
                calculatedPrice = calculatedPrice * this.booking.FareMultiplier;
            }

            return this.roundPrice(calculatedPrice);
        },
        calculatePriceUsingSteps(totalDistance) {
            const steps = JSON.parse(this.booking.FarePerMileSteps);

            steps.sort((step1, step2) => {
                if (step1.max === null) return 1;
                if (step2.max === null) return -1;
                return step1.max - step2.max;
            });

            switch (this.booking.StepsMode) {
                case 1:
                    return this.calculateCumulativeModePrice(totalDistance, steps);
                case 2:
                    return this.calculateFixedModePrice(totalDistance, steps);
                default:
                    return this.calculatePreciseModePrice(totalDistance, steps);
            }
        },
        calculatePreciseModePrice(totalDistance, steps) {
            let mileStart = 0;
            let total = 0;

            for (const step of steps) {
                if (step.max) {
                    const netDistance = Math.min(step.max, totalDistance) - mileStart;
                    total += netDistance * step.fare;
                    mileStart = step.max;
                    if (totalDistance <= step.max) break;
                } else {
                    const netDistance = totalDistance - mileStart;
                    total += netDistance * step.fare * (1 / (this.booking.FareUnit || 1));
                    break;
                }
            }

            return total;
        },
        calculateFixedModePrice(totalDistance, steps) {
            let mileStart = 0;
            let total = 0;

            for (const step of steps) {
                if (step.max) {
                    mileStart = step.max;
                    if (totalDistance <= step.max) {
                        return step.fare;
                    } else {
                        total = step.fare;
                    }
                } else {
                    const netDistance = totalDistance - mileStart;
                    total += netDistance * step.fare * (1 / (this.booking.FareUnit || 1));
                    break;
                }
            }

            return total;
        },
        calculateCumulativeModePrice(totalDistance, steps) {
            let mileStart = 0;
            let total = 0;

            for (const step of steps) {
                if (step.max) {
                    total += step.fare;
                    mileStart = step.max;
                    if (totalDistance <= step.max) break;
                } else {
                    const netDistance = totalDistance - mileStart;
                    total += netDistance * step.fare * (1 / (this.booking.FareUnit || 1));
                    break;
                }
            }

            return total;
        },
    },
    computed: {
        stationaryThreshold() {
            return (this.booking.StationarySpeedThreshold || 4) * MPH_TO_MPS;
        },
        minimumCharge() {
            let minCharge = this.booking.MinimumCharge || 0;
            if (this.booking.BookingStops && this.booking.BookingStops.length > 2 && this.booking.ExtraStopMinimum) {
                minCharge = Math.max(minCharge, this.booking.ExtraStopMinimum);
            }
            if (this.isWaitAndReturn && this.booking.WaitAndReturnMinimum) {
                minCharge = Math.max(minCharge, this.booking.WaitAndReturnMinimum);
            }
            return minCharge;
        },
        // dead mileage, extra stop charge
        extraCharges() {
            let charges = 0;
            if (this.booking.DeadMileageCharge) {
                charges += this.booking.DeadMileageCharge;
            }
            if (this.booking.ExtraStopCharge && this.booking.BookingStops && this.booking.BookingStops.length > 2) {
                charges += this.booking.ExtraStopCharge;
            }
            return charges;
        },
        isWaitAndReturn() {
            if (this.booking.WaitAndReturn) {
                return true;
            }

            if (this.booking.AutoWaitAndReturn && this.booking.BookingStops && this.booking.BookingStops.length === 3) {
                const pickup = this.booking.BookingStops[0];
                const drop = this.booking.BookingStops[2];
                if (pickup.Latitude && pickup.Longitude && drop.Latitude && drop.Longitude) {
                    const threshold = (this.booking.AutoWaitAndReturnRadius || 0.1) / 1000;
                    const distance = haversineDistance({latitude: pickup.Latitude, longitude: pickup.Longitude}, {latitude: drop.Latitude, longitude: drop.Longitude});
                    if (distance <= threshold) {
                        return true;
                    }
                }
            }

            return false;
        },
    },
    watch: {
        'booking.CompletingDateTime': {
            handler() {
                this.$nextTick(() => {
                    this.addPriceUpdate(null, true);
                });
            },
        },
        'booking.BookingStatus': {
            handler(newVal, oldVal) {
                if (newVal === 'InProgress' && oldVal !== 'InProgress') {
                    this.$nextTick(() => {
                        this.addPriceUpdate(null, true);
                    });
                }
            },
        },
    },
    mounted() {
        this.resyncLocations();
        bus.$on('refetchPrice', this.resyncLocations);
        bus.$on('bookingCompleted', this.uploadLocations);
        this.calcInterval = setInterval(this.addPriceUpdate, 5000);
    },
    destroyed() {
        bus.$off('refetchPrice', this.resyncLocations);
        bus.$off('bookingCompleted', this.uploadLocations);
        if (this.calcInterval) {
            clearInterval(this.calcInterval);
        }
    },
};
